import React,{useState, useEffect} from 'react';

const xhr = new XMLHttpRequest();
    xhr.onload = function() {
        console.log(this.responseXML)
    }
    xhr.open("GET", "feed.php?q=news", true);
    xhr.send();

const Ticker = () => {
    // const [item, setItem] = useState();
    // const [index, setIndex] = useState();
    // useEffect(()=> {
    //     fetch('../feed.php?q=news').then((response)=> response.json())
    //         .then( (json) => {
    //             setItem(json)
    //             console.log(json);
    //         })
    // }, [item])

  return (
    <div>
        This is the Ticker
    </div>
  )
}

export default Ticker
