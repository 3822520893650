import React from 'react'
import events from '../eventImg.json'

const DaysEvents = ({id, name, path, imgIndex, index, alt, fileType}) => {
    let position = "nextSlide";
    if(imgIndex === index) {
    position = "activeSlide";
    }
    if(imgIndex === index - 1 || (index === 0 && imgIndex === events.length - 1)){
    position = "lastSlide";
    }
  return (
    <div className={position} key={id}>
      <img src={path + name + '.' + fileType} alt={alt} />
    </div>
  )
}

export default DaysEvents
