import React from 'react';
import EventSlides from './EventSlides';
// import Events from './old/Events';
// import data from '../events.json';
function GlobalZone() {
    

  return (
      <>
            <EventSlides/>
      </>
  )
}

export default GlobalZone
