import { useEffect, useState } from 'react';
import logo from '../logo.svg';

const  updatedTime = () => {
    let newDate, currentTime, hour, min, meridiem;
        newDate = new Date();
        hour = ((newDate.getHours() + 11) % 12 + 1);
        min = newDate.getMinutes() < 10 ? `0${newDate.getMinutes()}`: newDate.getMinutes();
        meridiem = hour >= 12 ? 'pm' : 'am';
        currentTime = `${hour}:${min} ${meridiem}`;
        return currentTime;
}



function Header() {
 const [date, setDate] = useState(new Date());

 useEffect(()=>{
     var timerID = setInterval(() => tick(), 1000);

     return function cleanUp(){
         clearInterval(timerID);
     }
     
 });

 function tick() {
     setDate(new Date());
 }


    return ( 
        <div id="Header">
            <header className="Gt-Header">
                <img src={logo} className="Gt-logo" alt="logo" />
                <h1 className='customer-name'>Seriously Super Long School Name Associated with Georgia Tech</h1>
                <div>
                    {/* <h3>Weather</h3> */}
                </div>
                <div>
                    <h3>{date.toLocaleDateString()}</h3>
                    <h3>{updatedTime()}</h3>
                </div>
            </header>
        </div>
    )
}

export default Header;
