import React, {useState, useEffect} from "react";
import Ticker from "./Ticker";


function Footer() {
  return (
    <div className="Footer">
      <footer className="GT-Footer">
            {/* <ul className="Ticker">
              <li><h3>items</h3></li>
              <li><h3>items</h3></li>
              <li><h3>items</h3></li>
              <li><h3>items</h3></li>
              <li><h3>items</h3></li>
              <li><h3>items</h3></li>
              <li><h3>items</h3></li>
              <li><h3>items</h3></li>
              <li><h3>items</h3></li>
              <li><h3>items</h3></li>
              <li><h3>items</h3></li>
              <li><h3>items</h3></li>
              <li><h3>items</h3></li>
              <li><h3>items</h3></li>
              <li><h3>items</h3></li>
              <li><h3>items</h3></li>
              <li><h3>items</h3></li>
              <li><h3>items</h3></li>
              <li><h3>items</h3></li>
              <li><h3>items</h3></li>
            </ul> */}
            <Ticker />
            <div className="Qr"> qr-code </div>

      </footer>
    </div>
  );
}

export default Footer;
