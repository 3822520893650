import React from 'react';
import { useState, useEffect } from 'react';
import Events from '../eventImg.json';
import DaysEvents from './DaysEvents';


const EventSlides = () => {
  const [slide, setSlide] = useState(Events);
    const [index, setIndex] = useState(0);

    useEffect(()=>{
        const lastIndex = slide.length - 1;
        if( index < 0 ) {
          setIndex(lastIndex);
        }
        if(index>lastIndex){
          setIndex(0);
        }
      }, [index, slide]);
    
      useEffect(()=>{
        let slider = setInterval(()=>{
          setIndex(index+1);
        }, 3000)
        return () => clearInterval(slider)
      }, [index])
  return (
    <>
      {slide.map((img, imgIndex) => {
      return <DaysEvents key={img.id} {...img} imgIndex={imgIndex} index={index}/>
    })}
    </>
  )
}

export default EventSlides;
