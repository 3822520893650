import React from 'react'
import images from '../images.json'

function ClientZone({id, name, path, imgIndex, index, alt, fileType}) {
  // console.log(id, name, path, imgIndex, index, alt, fileType)
  let position = "nextSlide";
  if(imgIndex === index) {
    position = "activeSlide";
  }
  if(imgIndex === index - 1 || (index === 0 && imgIndex === images.length - 1)){
    position = "lastSlide";
  }
  return (
    <div className={position} key={id}>
      <img src={path + name + '.' + fileType} alt={alt} />
    </div>
  )
}

export default ClientZone;
