import ClientZone from './components/ClientZone';
import GlobalZone from './components/GlobalZone';
import { useEffect, useState} from 'react';
import  Images  from './images.json';

function App() {
  const [images, setImages] = useState(Images);
  const [index, setIndex] = useState(0);

  useEffect(()=>{
    const lastIndex = images.length - 1;
    if( index < 0 ) {
      setIndex(lastIndex);
    }
    if(index>lastIndex){
      setIndex(0);
    }
  }, [index, images]);

  useEffect(()=>{
    let slider = setInterval(()=>{
      setIndex(index+1)
    }, 3000)
    return () => clearInterval(slider)
  }, [index])


  return (
    <ul className="Slideshow">
    <li className="Client-content"> 
    {images.map((img, imgIndex) => {
      return <ClientZone key={img.id} {...img} imgIndex={imgIndex} index={index}/>
    })}
    </li>
    <li className="Events-content">
      <GlobalZone />
    </li>
  </ul>
  );
}

export default App;
